<template>
  <v-sheet>
    <cit-data-table
      ref="table"
      :headers="headers"
      resource-path="clients/franchisees"
    >

      <template v-slot:actions="{}">
        <new-button @click="openAddNew">Adauga cont de francizat</new-button>
        <v-spacer/>
        <export-client-list type="franchisees"/>
      </template>

      <template v-slot:[`item.actions`]="{item}">
        <view-button :to="{name:'view-franchisee', params:{id:item.id}}" target="_self"/>
        <action-delete :action-url="`/clients/franchisees/${item.id}`"/>
      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import HelpIcon from '@/components/HelpIcon'
import ViewButton from '@/components/general-form/ViewButton'
import NewButton from '@/components/general-form/NewButton'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ExportClientList from '@/views/Clients/ExportClientList'

export default {
  name: 'FranchiseesList',
  components: {
    ExportClientList,
    ActionDelete,
    NewButton,
    ViewButton,
    HelpIcon,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'Nume Firma',
          value: 'name',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          text: 'Domeniu Magazin',
          value: 'domain',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          text: 'Domeniu Admin',
          value: 'admin_domain',
          sortable: true,
          filterType: 'text',
          width: '200'
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: true,
          filterType: 'text',
          width: '150'
        },
        {
          text: 'Email',
          value: 'email',
          filterType: 'text',
          sortable: true,
          width: '100'
        }
      ]
    }
  },
  methods: {
    reload () {
      this.$refs.table.loadItems()
    },
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-franchisee',
        title: 'Adauga un cont de francizat'
      })
    },
  },
  created () {
    // this.$store.dispatch('agents/loadItems')
  }
}
</script>
